.fillter
	&-cont
		margin-bottom: 40px
		
	&-wrap
		display: flex
		justify-content: space-between

@media screen and (max-width: 1000px)
	
	.fillter
		&-wrap
			flex-wrap: wrap
			justify-content: flex-start

		.default-input--date
			width: 100%
			margin-top: 20px



@media screen and (max-width: 667px)
	
	.fillter-wrap
		flex-direction: column

		> *
			&:not(:first-child)
				margin-top: 20px

		.default-input
			margin-right: 0
