.popup-form
	display: none
	margin: 0 !important
	padding: 55px !important
	width: 820px

	&::-webkit-scrollbar
		width: 10px

		&-track
			background: var(--second-color)

		&-thumb
			background: var(--main-color)

	&__title
		font-size: 30px
		margin-bottom: 20px

		+ .popup-form__form
			margin: 60px 0 0

	&__text
		color: rgba(255,255,255, .3)
		margin: 0 auto 50px

	&__submit
		flex: none

	&__form
		display: flex
		flex-wrap: wrap
		margin: 0 0 -20px -20px

		.default-input 
			width: calc((100% - 20px * 2) / 2)
			margin: 0 0 20px 20px

			&.default-input--full
				width: 100%


		&--bot
			margin-top: 30px

	.default-input--submit
		input
			background: var(--main-color)
			color: #fff

	
	.forms

		&__error

			&-message
				top: calc(100% + 1px)
				left: 0
				right: 0
				box-shadow: none
				border: none
				background: none
				padding: 0 0 2px
				color: var(--mainColor)

				&:after
					display: none

		&__input

			&:focus

				~ .forms

					&__error

						&-message
							color: rgba(#000, .5)

.checkbox
	position: relative

	&__input
		position: absolute 0 * * 0
		opacity: 0
		pointer-events: none

		&:checked

			~ label

				&:after
					opacity: 1

	&__label
		cursor: pointer
		display: flex
		align-items: center
		position: relative
		user-select: none
		font-size: 14px

		&:before, &:after
			content: ""
			display: inline-block

		&:before
			flex: none
			background: #fff
			border: 1px solid #e8e8e8
			margin: 0 15px 0 0
			border-radius: 7px
			size: 32px

		&:after
			border: 2px solid var(--mainColor)
			border-top: none
			border-left: none
			size: 9px 12px
			transform: rotateZ(45deg)
			position: absolute 7px * * 12px
			transition: opacity .1s ease
			opacity: 0


.form-req
	color: rgba(255,255,255, .3)

.default-input
	display: flex
	flex-direction: column-reverse
	font-size: 14px
	position: relative

	&:not(:last-child)
		margin-right: 20px
	
	.default-input__input
		+ .default-input__input:not(.default-input__input--file-support)
			border-bottom: 0
			border-radius: 5px 5px 0 0 !important
			margin-bottom: -2px




	&--submit
		align-items: center
		flex-direction: row

		.form-req
			margin-left: 44px

	&__subtext
		font-size: 14px
		position: absolute calc(100% + 5px) 0 *

		span
			color: rgba(255, 255, 255, .3)

	&--date
		display: flex
		margin-left: auto
		margin-right: 0
		
		.default-input
			&:first-child
				order: 1


		&:before
			content: ''
			display: inline-block
			size: 6px 1px
			background: #000
			order: 1
			margin: auto 15px


	&__input
		position: relative
		border: none
		border: 1px solid #EDEDED
		height: 46px
		transition: border-color .3s ease
		width: 100%
		color: #000
		border-radius: 5px !important
		padding: 0 20px
		background-color: #FBFBFC







		&--error:not(:valid), &.error:not(:valid), &.forms__input_error:not(:valid)
			border-color: red

			~ .forms__error-message
				opacity: 1

			&:focus

			~ .forms__error-message
				opacity: 0



		&::-webkit-input-placeholder
			transition: opacity .3s ease
			color: rgba(#000, .5)

		&:-moz-placeholder
			transition: opacity .3s ease
			color: rgba(#000, .5)

		&::-moz-placeholder
			transition: opacity .3s ease
			color: rgba(#000, .5)

		&:-ms-input-placeholder
			transition: opacity .3s ease
			color: rgba(#000, .5)

		&:not(:focus)

			&::-webkit-input-placeholder
				opacity: .3
				color: #000

			&:-moz-placeholder
				opacity: .3
				color: #000

			&::-moz-placeholder
				opacity: .3
				color: #000

			&:-ms-input-placeholder
				opacity: .3
				color: #000

			&.js__have-content:valid
				// background-image: url("../img/ico-true.svg")

			&.js__have-content:invalid
				// background-image: url("../img/ico-close-red.svg")

		&:focus
			border-color: var(--main-color)
			// background-color: transparent

			&::-webkit-input-placeholder
				opacity: 0 !important

			&:-moz-placeholder
				opacity: 0 !important

			&::-moz-placeholder
				opacity: 0 !important

			&:-ms-input-placeholder
				opacity: 0 !important

		&--select
			padding: 0 30px 0 25px

			&:after
				content: ''
				display: block
				position: absolute
				right: 20px
				top: calc(50% - 3px)
				size: 6px
				border-right: 1px solid #000
				border-bottom: 1px solid #000
				transform: rotate(45deg)


		&:required ~ label

			&:after
				content: "*"
				display: inline-block
				margin-left: 5px
				color: var(--main-color)

		&--textarea
			max-width: 100%
			height: 124px
			resize: none
			padding: 13px 20px

		&--file
			opacity: 0
			position: absolute 0 * * 0
			// pointer-events: none

			&[readonly]

				~ .default-input__input--file-support
					width: 100%




			~ .default-input__input
				width: calc(100% - 147px)

			&-support
				padding: 0 20px !important

	&__label
		margin-bottom: 10px
		font-size: rem(10)
		text-transform: uppercase
		color: rgba(0, 0, 0, 0.4)
		pointer-events: all

		&--file
			cursor: pointer
			// margin-bottom: 0

			&[data-text]

				&:after
					content: attr(data-text)
					display: block
					font-size: 14px
					font-weight: bold
					white-space: nowrap
					overflow: hidden
					text-overflow: ellipsis
					margin-left: 10px
					position: absolute
					right: 9px
					bottom: 9px
					padding: 15px 20px 15px 50px
					background-color: var(--main-color)
					background-image: url('../img/ico-clip.svg')
					background-repeat: no-repeat
					background-position: left 18px center 
					color: #fff
					border-radius: 5px
					text-transform: initial
					z-index: 100


			&:not([data-text="Файл не выбран"])

				&:after
					color: #fff

			span
				flex: none
				border-radius: 100px
				background: url("../img/ico-add-file.svg") center right 18px no-repeat, var(--second-color)
				text-transform: uppercase
				font-size: 10px
				padding: 13px 45px 13px 24px
				display: inline-block

.forms
	
	&__error

		&-message
			border: 1px solid #d4d4d4
			background: #fff
			color: #000
			padding: 7px 10px
			border-radius: 7px
			position: absolute
			bottom: 45px
			right: -6px
			box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.1)
			transition: opacity .3s ease
			user-select: none
			font-size: 12px

			opacity: 0
			pointer-events: none

			&:after
				content: ""
				background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAIBAMAAADU/bjBAAAAGFBMVEX////V1dXV1dXY2Nji4uL9/f3+/v7///88REMWAAAAA3RSTlMAipDLFSdbAAAAM0lEQVQI12NwKweBMgcGlXQQI0WBgQkkVObEwAAWAgowgITAAiAhsABQyBUiwMAgLAAkAJ+wDc+KWF7qAAAAAElFTkSuQmCC)
				position: absolute
				top: calc(100% - 0px)
				right: 19px
				height: 8px
				width: 15px

			&-icon
				display: block
				border-radius: 50%
				position: absolute
				border: 1px solid #ff0024
				height: 19px
				width: 19px
				background: #fff
				bottom: -32px
				right: 16px

				&:before, &:after
					content: ""
					position: absolute
					width: 1px
					background: #ff0024
					left: calc(50% - 1px)

				&:before
					top: 4px
					height: 8px

				&:after
					bottom: 4px
					height: 2px


.forms-recaptcha

	+ .choose-form__bot
		margin-top: 20px


@media screen and (max-width: 1000px)
	.popup-form
		padding: 20px !important

		&__title
			font-size: 24px

		&__text
			margin-bottom: 20px

		&__info
			margin: 25px 0 0

	.forms

		&__req, &__submit
			margin: 20px 0 0


@media screen and (max-width: 667px)
	.fancybox

		&-slide

			&--html
				padding: 0 !important

				&:before
					display: none !important

	.forms

		&__inputs
			display: block
			margin: 0

		&__input

			&-cont
				width: auto !important
				margin: 0 !important

				&:not(:first-child)
					margin: 32px 0 0 !important

				&--double

					.forms

						&__input

							&-cont
								margin-top: 0 !important
								width: 100% !important

								&:not(:first-child)
									margin: 15px 0 0 !important

	.popup-form
		padding: 35px 16px !important
		height: 100%

		&__text
			font-size: 14px

		&__inputs
			margin: 0
			display: block

		&__form
			margin: 0 0 20px 0

		.default-input
			margin: 0
			width: 100% !important

			&:not(:first-child)
				margin: 20px 0 0

			&__label
				margin: 0

			&--submit
				display: block
				text-align: center

				.form-req
					margin: 20px 0 0

	.default-input

		&__label

			&--file
				flex-direction: column
				align-items: flex-start

				&:after
					margin: 10px 0 0 !important
					width: 100%

	.default-input__label--file[data-text]:after
		position: static
		margin-bottom: 10px !important