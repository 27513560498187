.title-block
	display: flex
	justify-content: space-between
	margin: 70px 0 60px

	&__btn
		display: flex

		.btn
			&:not(:first-child)
				margin-left: 20px


	&:first-child:not(:last-child)
		padding: 84px 0 0

	&__title
		margin-right: 20px

		h1
			font-size: 30px
			line-height: normal

	&__menu
		flex: none

.title-menu
	display: flex

	li

		&:not(:last-child)
			margin-right: 22px


@media (max-width: 1500px)
	.title-block
		margin: 25px 0

		&__title

			h1
				font-size: 25px


@media (max-width: 1000px)
	.title-block
		margin: 25px 0

		&__title

			h1
				font-size: 25px

@media (max-width: 667px)
	
	.title-block
		flex-direction: column
		margin: 20px 0

		&__btn
			margin-top: 20px
