@import "../css/common.css"
// @import "swiper/dist/css/swiper.css"

@import "_vars.sss"
@import "_base.sss"
@import "_text-styles.sss"
@import "_form-elements.sss"
@import "_title-block.sss"
@import "_fillter.sss"
// @import "_paginator.sss"

@import "_header.sss"

.table
	width: 100%
	border-collapse: collapse
	border: 1px solid #F1F3F4

	td
		border: 1px solid rgba(196, 196, 196, 0.3)

	th
		&:not(:first-child)
			border-left: 1px solid rgba(196, 196, 196, 0.3)

	thead
		background: #F1F3F4
		border-radius: 5px 5px 0 0
		text-align: left


		div
			padding: 13px 18px

	tr
		&:nth-child(odd)
			background: rgba(241, 243, 244, 0.3)

	td
		vertical-align: middle
		text-align: left

		span
			display: inline-block

			&:not(:last-child)
				margin-bottom: 10px


		// &:last-child
		// 	div
		// 		display: flex
		// 		align-items: center

		> div
			padding: 13px 18px

			&:after
				content: ''
				clear: both
				display: block

			span
				&:after
					content: ''
					clear: both
					display: block


	.ico
		size: 18px
		float: left
		margin-right: 15px
		overflow: visible !important
		margin-top: -3px


.status
	span
		white-space: nowrap

	&--pause
		.ico
			fill: rgba(32, 22, 0, 0.7)

	&--no
		.ico
			fill: var(--main-color)

	&--done
		.ico
			fill: #92C242

	&-btn
		float: left
		white-space: nowrap
		margin-top: 10px
		display: flex

		.btn
			&:not(:first-child)
				margin-left: 10px

			&:nth-child(even)
				background: var(--main-color)
				color: #fff

.ordering
	display: flex

	&-form
		width: calc(100% - 80px - 616px)
		margin-right: 80px

		.form-wrap
			display: flex
			flex-wrap: wrap
			margin: 0 0 -20px -20px

		.default-input
			width: calc((100% - 20px * 2) / 2)
			margin: 0 0 20px 20px

			&.default-input--full
				width: 100%


	&-info
		width: calc(100% - 80px - 503px)
		margin-left: auto

		.form-wrap
			display: flex



.comments
	margin-top: 45px

	&-send

		.default-input
			margin-right: 23px
			width: calc(100% - 117px - 23px)
			margin-right: 23px

			&--submit
				margin-top: 24px

				input
					background: var(--main-color)
					color: #fff


	&-title
		background: #F1F3F4
		border-radius: 5px
		font-size: rem(18)
		padding: 13px 20px
		margin-bottom: 32px

	&-list
		max-height: 525px
		overflow-y: auto
		overflow-x: visible
		padding-right: 48px


		&::-webkit-scrollbar 
			width: 2px



		&::-webkit-scrollbar-track 
			background: #FBFBFC
			border-radius: 5px


		&::-webkit-scrollbar-thumb 
			border-radius: 5px
			background: var(--main-color)


	&-item
		display: block
		font-size: rem(14)
		padding: 20px 0

		&:not(:first-child)
			border-top: 1px solid rgba(196, 196, 196, 0.3)


		&__date
			margin-bottom: 15px


.search
	position: relative
	display: flex

		

	.ico-zoom
		position: absolute
		top: calc(50% - 6px)
		left: 17px
		pointer-events: none
		size: 12px

		.ico
			size: 12px

	input
		&[type="text"]
			max-width: 512px
			width: 100%
			border-radius: 5px
			border: 1px solid #ededed
			padding: 0 20px 0 45px

			&:valid
				~ input[type="submit"]
					background: var(--main-color)
					color: #fff
					pointer-events: all

			&:focus, &:active
				~ .ico-zoom
					svg
						fill: var(--main-color)



		&[type="submit"]
			margin-left: 20px
			pointer-events: none


	&-cont
		padding: 45px 0

.form-modified
	.default-input
		margin-right: 0

		&:not(:first-child)
			margin-top: 20px

		&--submit
			margin-top: 45px

			input
				background: var(--main-color)
				color: #fff

		&__input
			&:not(.dafault-input__label--file), &:not(.default-input__input--file-support)
				border: 1px solid transparent
				padding: 31px 10px 13px 23px
				height: 67px
				transition: border-color .3s ease,background-color .3s ease
				width: 100%
				background-color: #f8f8f8
				background-position: right 26px center
				background-repeat: no-repeat

				&:focus
					border-color: #eaeaed
					background-color: transparent

			&.default-input__input--textarea
				height: 124px


		&__label
			&:not(.default-input__label--file)
				margin-bottom: 10px
				flex: none
				top: 0
				left: 0
				position: absolute
				padding: 24px 0 0 22px
				transition: font-size .3s ease,transform .3s ease
				pointer-events: none
				font-size: 10px
				text-transform: uppercase
				font-weight: bold
				color: rgba(0,0,0,.5)
				transform: translate3d(0,-12px,0)



.login
	&-cont
		background: #000
		height: 100vh
		width: 100vw
		display: flex
		justify-content: center
		align-items: center

	&-form
		max-width: 455px
		width: 100%
		padding: 60px 70px
		border-radius: 5px
		background: #fff

		&__title
			font-size: rem(24)
			margin-bottom: 45px


		.default-input
			margin-right: 0

			&:not(:first-child)
				margin-top: 20px

			&--submit
				margin-top: 45px

				input
					background: var(--main-color)
					color: #fff

@media screen and (max-width: 1400px)

	.ordering-form
		width: calc(100% - 648px)
		margin-right: 30px

@media screen and (max-width: 1200px)
	
	.ordering
		&-form, &-info
			width: 50%
			
		&-form
			margin-right: 30px


@media screen and (max-width: 1000px)
	
	.search-cont
		padding: 20px 0


	.ordering-form

		.form-wrap
			margin: 0 0 -20px 0

		.default-input
			width: 100%
			margin: 0 0 20px 0

	.comments
		margin-top: 20px

		&-title
			margin-bottom: 20px
			font-size: 1rem
			padding: 11px 15px

		&-send 
			.form-wrap
				flex-direction: column

			.default-input
				width: 100%
				margin-right: 0

		&-item
			padding: 15px 0

			&__date
				margin-bottom: 10px

		&-list
			padding-right: 20px



@media screen and (max-width: 667px)
	
	.head-cont
		padding: 20px

	#content > .wrapper
		padding: 15px
	
	.table 
		.ico
			margin-right: 4px

		td > div
			padding: 9px

	.search-cont
		padding: 0

	.ordering
		flex-direction: column

		&-form, &-info
			width: 100%

	.comments-list
		max-height: 340px

	.login-form
		padding: 20px
		height: 100%
		border-radius: 0

		&__title
			margin-bottom: 20px





// @import "_standart-page.sss"

// @import "_footer.sss"