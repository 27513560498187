.head
	display: flex
	justify-content: space-between

	&__burger
		display: none
		background: #000

	.btn

		&:hover
			background: #3D3D3D

		&:not(:last-child)
			margin-left: 20px

		&:last-child
			margin-left: auto

	&-cont
		background: #000
		color: #fff
		padding: 34px 0
		position: sticky
		top: 0
		z-index: 11

		a
			color: #fff

	.ico
		size: 16px

		fill: var(--main-color)

.burger
	z-index: 9999
	display: flex
	justify-content: center
	align-items: center
	box-sizing: content-box
	width: 40px
	height: 30px
	padding: 15px
	background: 0 0
	border: 0
	user-select: none
	margin: -15px -15px -15px 20px
	cursor: pointer
	pointer-events: auto
	
	&:before 
		display: none

	span
		position: relative
		display: inline-block
		width: 40px
		height: 2px
		border-radius: 5px
		vertical-align: bottom
		background: #fff
		transition: background .2s .4s

		&:before
			border-radius: 5px
			transform: none
			content: ""
			position: absolute
			left: 0
			top: 8px
			width: 100%
			height: 2px
			background: #fff
			transform-origin: 50% 50%
			transition: top .3s .3s,transform .3s

		&:after
			border-radius: 5px
			content: ""
			position: absolute
			left: 0
			top: -8px
			width: 100%
			height: 2px
			background: #fff
			transform-origin: 50% 50%
			transition: top .3s .3s,transform .3s


.js__menu--open

	background: #000

	.head-cont
		background-color: transparent



	footer 
		transform: translate3d(-250px, 0, 0)
	.mobile-menu
		// overflow-y: scroll
		overflow-x: hidden
		opacity: 1
		transform: scale(1)
		pointer-events: all
		filter: blur(0)

	.submenu
		display: block
		height: 100vh !important
	.burger>span
		background: 0 0
	.burger>span::before
		transform: rotate3d(0,0,1,45deg)
		top: 0
		transition: top .3s,transform .3s .5s
	.burger>span::after 
		transform: rotate3d(0,0,1,-45deg)
		top: 0
		transition: top .3s,transform .3s .5s
	.menu
		transform: translate3d(0,0,0)

		&__item 
			transform: translate3d(0,0,0)


.mobile-menu
	position: absolute
	top: 0
	right: 0
	bottom: 0
	width: 100%
	background: #000
	opacity: 0
	transform: scale(0)
	transition: opacity .3s ease, transform .3s ease, filter .5s ease
	z-index: 10
	pointer-events: none
	filter: blur(10px)

	.head
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		height: 100vh

		.btn
			margin: 0
			color: #fff

			&:not(:first-child)
				margin-top: 20px

			&:last-child
				margin-left: 0



@media screen and (max-width: 1000px)
	
	.head
		&-cont
			padding: 25px 0


			.wrapper
				display: flex

			.head
				display: none

		&__burger
			display: block
			margin-left: auto



@media screen and (max-width: 667px)
	
	.head
		&-cont
			padding: 0