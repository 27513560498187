html
	font-size: 100%

input, a, button, textarea, select
	outline: none

*::-webkit-input-placeholder
	color: rgba(255, 255, 255, .3)
	opacity: 1

*:-moz-placeholder
	color: rgba(255, 255, 255, .3)
	opacity: 1

*::-moz-placeholder
	color: rgba(255, 255, 255, .3)
	opacity: 1

*:-ms-input-placeholder
	color: rgba(255, 255, 255, .3)
	opacity: 1

.swiper-button

	&-disabled
		opacity: .9 !important
		pointer-events: none
		background-color: var(--thrid-color) !important

	&-next, &-prev
		transition: background-color .3s ease

		&:hover, &:focus
			background-color: var(--thrid-color) !important

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea, select, input[type="submit"], button
	-webkit-appearance: none
	appearance: none
	font-family: inherit

	&:focus

		&::-webkit-input-placeholder
			opacity: 0

		&:-moz-placeholder
			opacity: 0

		&::-moz-placeholder
			opacity: 0

		&:-ms-input-placeholder
			opacity: 0

input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea, select
	border-radius: 0

// #{$all-text-inputs}

// 	&:not(.form-control):not(.comment-form__text)
	
// 		&::-webkit-input-placeholder
// 			color: #fff
// 			opacity: 1

// 		&:-moz-placeholder
// 			color: #fff
// 			opacity: 1

// 		&::-moz-placeholder
// 			color: #fff
// 			opacity: 1

// 		&:-ms-input-placeholder
// 			color: #fff
// 			opacity: 1

*
	-webkit-tap-highlight-color: transparent
	tap-highlight-color: transparent

*, *:after, *:before
	box-sizing: border-box

*[v-cloak]
	display: none

i.rub
	font-family: "Roboto"
	font-style: normal

address
	font-weight: inherit

body
	min-width: 320px
	line-height: lh(20, 14)
	font-family: var(--default-font)
	font-weight: 400
	font-size: 14px
	background: #fff
	color: #000
	position: relative
	left: 0
	display: flex
	flex-direction: column
	width: 100%
	min-height: 100vh
	overflow-y: scroll
	overflow-x: hidden


select
	-webkit-appearance: none
	/* for FF */
	-moz-appearance: none
	text-indent: 0.01px
	text-overflow: ''
	/* for IE */
	-ms-appearance: none
	appearance: none!important

	&::-ms-expand 
		display: none

.head, .footer
	flex: none

#content
	// height: 100%
	display: flex
	flex-direction: column
	flex: 1 1 auto
	z-index: 2
	background: #fff
	// margin-bottom: 140px

	> .wrapper
		border-top: 1px solid rgba(196, 196, 196, 0.3)
		padding-top: 45px

a
	color: #000
	font-weight: inherit
	text-decoration: none

	&[href^="tel"], &[href^="mail"]
		white-space: nowrap

	&:not([class])
		color: var(--main-color)
		border-bottom: 1px solid transparent

		&:hover, &:focus
			border-color: currentColor

.wrapper
	width: var(--base-width)
	max-width: 100%
	padding: 0 20px
	margin: 0 auto
	box-sizing: content-box

input[type="submit"], button
	cursor: pointer

img, svg
	max-width: 100%

li
	list-style: none

.swiper-wrapper:not([style*="height"]), .swiper-slide:not([style*="height"])
	height: auto !important

.table-wrap
	position: relative

	&__track
		overflow-x: auto
		-webkit-overflow-scrolling: touch

	&__shadow
		width: 12px
		position: absolute 0 *
		pointer-events: none
		background: linear-gradient(to right, rgba(#fff, .0001), rgba(#000, .1))
		opacity: 0

		&--left
			left: 0	
			transform: rotateZ(180deg)

		&--right
			right: 0

.ico
	display: block

.soc
	display: flex
	flex-wrap: wrap
	margin-bottom: -10px

	&__link
		display: flex
		align-items: center
		justify-content: center
		margin-bottom: 10px
		background: var(--main-color)
		size: 42px
		border-radius: 50%
		transition: background-color .3s ease

		&:hover, &:focus
			background-color: var(--thrid-color)

		&:not(:last-child)
			margin-right: 17px


.swiper-list
	overflow: hidden

.default-btn
	color: #fff 
	background: var(--main-color)
	text-transform: uppercase
	font-size: 10px
	border-radius: 100px
	border: none
	display: inline-block
	padding: 13px 22px 
	transition: background-color .3s ease

	&:hover, &:focus
		background-color: lighten(#f60, .1)



.btn
	white-space: nowrap
	padding: 15px 20px
	border-radius: 5px
	border: 1px solid rgba(237, 237, 237, 0.3)
	line-height: 1
	display: flex
	align-items: center
	transition: background .3s ease, color .3s ease 

	&:hover
		background: var(--main-color)
		color: #fff




	.ico
		margin-right: 16px

	
@media screen and (max-width: 1500px)
	
	#content 
		> .wrapper
			padding-top: 25px

@media screen and (max-width: 1200px)
	
	.wrapper
		width: 100%
		max-width: 100%
		box-sizing: border-box


